<template>
    <article class="mentor-card">


        <div class="contenedor-imagen">
            <span class="imagen" :style="(data.image_url) ? `background:url(${data.image_url}) center/cover no-repeat` : null"></span>
        </div>
        <h2 class="nombre">{{data.name}} {{data.surname}} {{data.surname_2}}</h2>
        <h3>{{data.profesional_current}}</h3>
        <h4>{{data.profesional_current_company}}</h4>
        <h4>{{data.province}}</h4>

        <a :href="data.profesional_linkedin" class="linkedin" v-if="data.profesional_linkedin">
            <i class="fa fa-linkedin"></i>
            @linkedin
        </a>

        <p>{{data.profesional_resumen}}</p>
        <p>
            <strong>Áreas profesionales:</strong> 
            <template v-for="(n,key) in data.area">
                {{n}}<template v-if="key != data.area.length-1">;</template>
            </template>
            <span v-if="data.area == 0">Sin definir</span>
        </p>
        <p>
            <strong>Sectores:</strong>
            <template v-for="(n,key) in data.sector">
                {{n}}<template v-if="key != data.sector.length-1">;</template>
            </template>
            <span v-if="data.sector == 0">Sin definir</span>
        </p>

        <p>
            <strong>Habilidades:</strong>
            <template v-for="(n,key) in data.ability">
                {{n}}<template v-if="key != data.ability.length-1">;</template>
            </template>
            <span v-if="data.ability == 0"> Sin definir</span>
        </p>


        <p><strong>Modalidad de mentoría:</strong> {{domains.preferences[data.preferences_participation]}}</p>

        <router-link :to="$t('links.fichamentor')+'/'+data.id" class="enlace-cover" target="_blank"></router-link>

    </article>
</template>


<script>

    
    import {mapGetters} from 'vuex'

    export default{
        name : 'mentor-card',
        props : ['data'],
        computed : {
            ...mapGetters({
                domains : 'getDomains'
            })
        }
    }

</script>