<template>

    <div class="wrapper">
        <section class="encabezado -red-mentores">
            <h1>Red de mentores</h1>
        </section>

        <section class="intro-text">
            <h2>Crea sinergias con otros profesionales UC3M</h2>
        </section>  


        <template v-if="user.visible_by_others == 1">    
            
            <section class="contenedor-mentores container row-start ais" v-if="data">
                <mentor-ficha :key="key" :data="n" v-for="(n,key) in data"></mentor-ficha>
            </section>
            
            <div v-else class="loading"></div>
            
        </template>

        <template v-if="user.visible_by_others == 0">

            <section class="contenedor-mentores container column-center">

                <p class="zero-results">Si quieres visualizar la red de mentores y que aparezcas en la lista para otros, puedes activar en tu perfil <strong>"Acepto formar parte de la Red de Mentores de este programa"</strong></p>

                <router-link class="btn -dark-orange" :to="$t('links.miperfil')">Ir a mi perfil</router-link>
                
            </section>

        </template>

        <div v-if="!user" class="loading"></div>

    </div>

</template>


<script>

    import mentor from '@/components/mentor-card'



    import { mapActions,mapGetters } from 'vuex';

    export default {
        name: 'red-mentores',
        mounted() {
            this.setData();
        },
        data : () => ({
            filtro : null,
            data : null,
            loading : false
        }),
        components: {
            'mentor-ficha': mentor,
        },
        computed : {
            ...mapGetters({
                user : 'getuser'
            })
        },
        methods: {
            ...mapActions(['requestMentores']),
            setData() {

                this.loading = true;

                this.requestMentores(null).then(r => {
                    this.data = r;
                    this.loading = false;
                });
            }
        }
    }
</script>